import Swipe from 'swipejs'

var animationLength = 500; // Change this in _grensesnitt-lightbox.scss as well if changed

var $grensesnittLightboxContainer = $('.gl-container');
var $lightboxes = $('.lightbox');
var $navPrev = $('.gl-nav-prev > a');
var $navNext = $('.gl-nav-next > a');
var swipe;
var noMobileScroll;

$('body').on('keydown', function(e) {
    if (!$('.gl-container').hasClass('show')) {
        return;
    }

    switch (e.keyCode) {
        case 39:
            swipe.next();
            e.preventDefault();
            break;
        case 37:
            swipe.prev();
            e.preventDefault();
            break;
        case 27:
            hideLightbox();
            e.preventDefault();
            break;
    }
});

$lightboxes.click(function (e) {
    e.preventDefault();

    showLightbox();

    if (swipe) {
        swipe.kill();
        swipe = null;
    }

    var $images = $(this).parents('.image-inner').find('.lightbox');
    addImages($images);

    swipe = new Swipe($('.gl-swipe')[0], {
        draggable: $images.length > 1,
        continuous: true,
        disableScroll: true
    });

    $grensesnittLightboxContainer[0].addEventListener('touchmove', function (e) {
        e.preventDefault();
    });

    var index = parseInt($(this).data('index')) || 0;
    swipe.slide(index, 1);

    var $glNav = $('.gl-nav');
    if (swipe.getNumSlides() <= 1) {
        $glNav.hide();
    } else {
        $glNav.show();
    }
});

$('.gl-container .gl-close').click(function (e) {
    e.preventDefault();

    hideLightbox();
});

$navNext.click(function (e) {
    e.preventDefault();

    swipe.next();
});

$navPrev.click(function (e) {
    e.preventDefault();

    swipe.prev();
});

$('.gl-image').click(function (e) {
    if (e.target.tagName === 'IMG') {
        return;
    }

    hideLightbox();
});

function addImages($images) {
    var $swipeWrap = $('.gl-swipe-wrap');

    $swipeWrap.html('');

    $images.each(function (index, el) {
        var url = el.href;
        var title = $(el).find('h4').text();

        $swipeWrap.append('<div>' + (title ? '<h4>' + title + '</h4>' : '') + '<img src="' + url + '" /></div>')
    })
}

function hideLightbox() {
    $grensesnittLightboxContainer.removeClass('fade');
    $('body').removeClass('no-scroll');

    setTimeout(function () {
        $grensesnittLightboxContainer.removeClass('show');
        $('body').removeClass('noscroll');
    }, animationLength);
}

function showLightbox() {
    $grensesnittLightboxContainer.addClass('show');
    $('body').addClass('no-scroll');

    setTimeout(function () {
        $grensesnittLightboxContainer.addClass('fade');

        setTimeout(function () {
            $('body').addClass('noscroll');
        }, animationLength);
    }, 10);
}
