
const favoritesKey = 'favorites';

const getFavoritesFlat = () => {
    let favorites = getFavorites();
    let flatFavorites = [];

    for (var i = 0; i < favorites.length; i++) {
        var favorite = favorites[i];
        flatFavorites.push('' + favorite.pageId + favorite.unit);
    }

    return flatFavorites;
};

const getFavorites = () => {
    let favorites = [];
    try {
        favorites = JSON.parse(localStorage.getItem(favoritesKey)) || [];
    } catch (ignored) {}

    for (var i = favorites.length; i--; i >= 0) {
        if (favorites[i]['unit'] === 'n-a') {
            favorites.splice(i, 1);
        }
    }

    return favorites;
};

const addToFavorites = (pageId, unitSlug) => {
    let favorites = getFavorites();
    let existingIndex = -1;

    for (var i = 0; i < favorites.length; i++) {
        let fav = favorites[i];
        if (fav.pageId === pageId && fav.unit === unitSlug) {
            existingIndex = i;
            break;
        }
    }

    if (existingIndex !== -1) {
        favorites.splice(existingIndex, 1);
    } else {
        favorites.push({pageId: pageId, unit: unitSlug});
    }

    localStorage.setItem(favoritesKey, JSON.stringify(favorites));

    return favorites;
};


export {
    getFavorites,
    getFavoritesFlat,
    addToFavorites
}