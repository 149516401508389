/**
 *
 * Create new object:
 * let dp = DrawPolygon();
 *
 * dp.setPolygonCallback(callback)
 * set the callback from drawPolygon and editPolygon. Will result in
 * (data) = {svgId, polyId, points}
 *
 * dp.drawPolygon(svgId, polyId)
 * Start drawing a new polygon
 *
 * dp.editPolygon(svgId, polyId)
 * Editing a polygon.
 *
 * dp.deletePolygon(svgId, polyId)
 * Remove a polygon from svg
 *
 * dp.setCircleRadius(radius)
 * change the circle radius from default 4
 *
 */


class DrawPolygons {
    constructor() {
        this.SVG = "http://www.w3.org/2000/svg";
        this.circleRadius = 4;
        this.polygonCallback = null;
        this.allowEditing = false;
    }

    setPolygonCallback(callback) {
        this.polygonCallback = callback;
    }

    setCircleRadius(radius) {
        this.circleRadius = radius;
    }

    resetPolygonEdit (svgId) {
        let svg = $("#" + svgId)[0];
        $(svg).off('mousedown');
        $(svg).off('mouseup');
        $(svg).off('mousemove');
        $(svg).find('polygon').off('mousedown');
        $(svg).find('polygon').off('mouseup');
        $(svg).find('polygon').off('mousemove');
    }

    deletePolygon(svgId, polyId) {
        $("#" + svgId).find("#" + polyId + '-poly').remove();
    }

    drawPolygon(svgId, polyId, polyName, polyIndex) {
        let self = this;
        if (!this.allowEditing) {
            return;
        }

        var $existingPolygon = $('#' + polyId + '-poly');
        if ($existingPolygon.length > 0) {
            $existingPolygon.remove();
        }

        let svg = $("#" + svgId)[0];
        let polygon = document.createElementNS(self.SVG, "polygon");

        self.resetPolygonEdit(svgId);
        polygon.setAttribute('id', polyId + '-poly');
        polygon.setAttribute('data-id', polyId);
        polygon.setAttribute('data-name', polyName);
        polygon.setAttribute('data-index', polyIndex);
        $(polygon).addClass('available');
        $(polygon).addClass('edit');
        svg.appendChild(polygon);
        let firstCircle = null;
        let lastCircle = null;
        let line = null;

        const removeSelectedGroup = (svg) => {
            $(svg).find(".selectedgroup").remove();
        }

        const getSelectedGroup = (svg) => {
            let group = $(svg).find(".selectedgroup");
            if (group.length === 0) {
                group = document.createElementNS(self.SVG, "g");
                $(group).addClass("selectedgroup");
                svg.appendChild(group);
            } else {
                group = group[0];
            }
            return group;
        }

        removeSelectedGroup(svg);
        let group = getSelectedGroup(svg);

        const addPoint = (point) => {
            let newPoints = [...polygon.points];
            newPoints.push(point);
            $(polygon).attr("points", newPoints.map(p => p.x + "," + p.y).join(" "));
            const points = $(polygon)[0].points;
            return points[points.length - 1];
        }

        const finishPolygon = () => {

            removeSelectedGroup(svg);
            $(svg).off('mousedown');
            $(firstCircle).off('mousedown');
            $(lastCircle).off('mousedown');
            $(polygon).removeClass('edit');
            self.editPolygon(svgId, polyId);
            if (self.polygonCallback) {
                self.polygonCallback({
                    svgId: svgId,
                    polyId: polyId,
                    polyName: polyName,
                    polyIndex: polyIndex,
                    points: [...polygon.points].map(p => p.x + "," + p.y).join(" ")
                });
            }
        }

        $(svg).mousemove(function (e) {
            let bBox = svg.getBBox();
            let rect = svg.getBoundingClientRect();
            let scale = bBox.width / rect.width;

            let x = (e.offsetX * scale);
            let y = (e.offsetY * scale);
            if (!line) {
                line = document.createElementNS(self.SVG, 'line');
                $(line).addClass("line");
                group.appendChild(line);
            }
            if (lastCircle) {
                line.setAttributeNS(null, 'x1', lastCircle.cx.baseVal.value);
                line.setAttributeNS(null, 'y1', lastCircle.cy.baseVal.value);
                line.setAttributeNS(null, 'x2', x);
                line.setAttributeNS(null, 'y2', y);
            }
        })

        $(svg).mousedown(function (e) {
            let bBox = svg.getBBox();
            let rect = svg.getBoundingClientRect();
            let scale = bBox.width / rect.width;
            let x = (e.offsetX * scale);
            let y = (e.offsetY * scale);
            let point = addPoint({x: x, y: y});
            let circle = document.createElementNS(self.SVG, 'circle');
            circle.setAttributeNS(null, 'cx', point.x);
            circle.setAttributeNS(null, 'cy', point.y);
            circle.setAttributeNS(null, 'r', self.circleRadius);
            $(circle).addClass("change-circle");
            group.appendChild(circle);

            if (!firstCircle) {
                firstCircle = circle;
                $(firstCircle).mousedown(function (e) {
                    finishPolygon();
                })
            } else {
                if (lastCircle && lastCircle !== firstCircle)
                    $(lastCircle).off('mousedown');
                $(circle).mousedown(function (e) {
                    finishPolygon();
                })
            }
            lastCircle = circle;
            if (line) {
                line.setAttributeNS(null, 'x1', x);
                line.setAttributeNS(null, 'y1', y);
                line.setAttributeNS(null, 'x2', x);
                line.setAttributeNS(null, 'y2', y);

            }
        })

    }

    editPolygon(svgId, polyId, polyName) {
        let self = this;
        if (!self.allowEditing) {
            return;
        }

        const getSelectedGroup = (svg) => {
            let group = $(svg).find(".selectedgroup");
            if (group.length === 0) {
                group = document.createElementNS(self.SVG, "g");
                $(group).addClass("selectedgroup");
                svg.appendChild(group);
            } else {
                group = group[0];
            }
            return group;
        }

        const getPolygon = (svg, polyId) => {
            let polygon = $(svg).find("#" + polyId + '-poly');
            if (polygon.length === 0) {
                polygon = document.createElementNS(self.SVG, "polygon");
                polygon.setAttribute('id', polyId + '-poly');
                polygon.setAttribute('data-id', polyId);
                polygon.setAttribute('data-name', polyName);
                polygon.setAttribute('class', 'available');
                $(polygon).addClass('available');
                svg.appendChild(polygon);
            } else {
                polygon = polygon[0];
            }
            return polygon;
        }

        this.resetPolygonEdit(svgId);
        let svg = $("#" + svgId)[0];
        let polygon = getPolygon(svg, polyId);
        let group = getSelectedGroup(svg);
        for (let p of $(svg).find("polygon")) {
            $(p).on('mousedown', function (e) {
                $(svg).find("polygon").off("mousedown");
                self.editPolygon(svgId, $(this).data('id'), $(this).data('name'));
            })
        }

        $(polygon).addClass("border");
        let movingPoint = null;
        let movingCircle = null;
        let downX = 0;
        let downY = 0;
        let scale = 0;

        const removePoint = (polygon, point) => {
            let newPoints = [];
            for (let pos = 0; pos < polygon.points.length; pos++) {
                const p = polygon.points[pos];
                if (p.x !== point.x || p.y !== point.y)
                    newPoints.push(p);
            }

            $(polygon).attr("points", newPoints.map(p => p.x + "," + p.y).join(" "));
            movingPoint = null;
            movingCircle = null;

            createCircles();
        };

        const addNewPointBetween = (e, point, x, y) => {
            let newPoints = [];
            let added = false;
            for (let pos = 0; pos < polygon.points.length; pos++) {
                const p = polygon.points[pos];
                newPoints.push(p);
                if (!added) {
                    if (p.x === point.x && p.y === point.y) {
                        added = true;
                        newPoints.push({x: x, y: y});
                    }
                }
            }
            $(polygon).attr("points", newPoints.map(p => p.x + "," + p.y).join(" "));
            createCircles();
            let circles = $(group).find("circle");
            for (let circle of circles) {
                if (circle.cx.baseVal.value === x && circle.cy.baseVal.value === y) {
                    movingPoint = circle.addedPoint;
                    movingCircle = circle;
                    let bBox = svg.getBBox();
                    let rect = svg.getBoundingClientRect();
                    scale = bBox.width / rect.width;
                    downX = (e.pageX * scale) - movingPoint.x;
                    downY = (e.pageY * scale) - movingPoint.y;
                    break;
                }
            }
        };


        const createCircle = (point) => {
            let circle = document.createElementNS(self.SVG, 'circle');
            circle.setAttributeNS(null, 'cx', point.x);
            circle.setAttributeNS(null, 'cy', point.y);
            circle.setAttributeNS(null, 'r', self.circleRadius);
            $(circle).addClass("change-circle");
            $(circle).mousedown(function (e) {
                movingPoint = point;
                movingCircle = circle;
                let bBox = svg.getBBox();
                let rect = svg.getBoundingClientRect();
                scale = bBox.width / rect.width;
                downX = (e.pageX * scale) - movingPoint.x;
                downY = (e.pageY * scale) - movingPoint.y;
            })
            circle.addedPoint = point;
            group.appendChild(circle);
            return circle;
        }

        const addCircleToPoint = (circle, point1, point2) => {
            if (!('circle' in point1))
                point1.circle = [];
            if (!('circle' in point2))
                point2.circle = [];
            point1.circle.push([circle, point2]);
            point2.circle.push([circle, point1]);
            circle.attachedPoints = [point1, point2];
        };


        const createHalfCircle = (point1, point2) => {
            let x = point2.x + (point1.x - point2.x) / 2;
            let y = point2.y + (point1.y - point2.y) / 2;
            let circle = document.createElementNS(self.SVG, 'circle');
            circle.setAttributeNS(null, 'cx', x);
            circle.setAttributeNS(null, 'cy', y);
            circle.setAttributeNS(null, 'r', self.circleRadius);
            $(circle).addClass("change-circle");
            $(circle).addClass("change-half");
            $(circle).mousedown(function (e) {
                addNewPointBetween(e, circle.attachedPoints[0], circle.cx.baseVal.value, circle.cy.baseVal.value);
            });
            addCircleToPoint(circle, point1, point2);
            group.appendChild(circle);
            return circle;
        }

        const deletePoint = (point) => {
            removePoint(polygon, point);
        }

        const deleteCircle = (circle) => {
            $(circle).remove();
        }

        const createCircles = () => {
            removeSelectedGroup(svg);
            group = getSelectedGroup(svg);
            let pointList = polygon.points;
            let lastPoint = pointList.length > 0 ? pointList[pointList.length - 1] : null;
            let firstCircle = null;
            for (let pos = 0; pos < pointList.length; pos++) {
                let point = pointList[pos];
                point.id = pos;
                createCircle(point);
                let circle = null;
                if (lastPoint) {
                    circle = createHalfCircle(lastPoint, point);
                }
                if (pos === pointList.length - 1 && firstCircle && pos !== 0) {
                    addCircleToPoint(firstCircle, point, pointList[0]);
                }
                if (!firstCircle)
                    firstCircle = circle;
                lastPoint = point;
            }
        }

        const removeSelectedGroup = (svg) => {
            $(svg).find(".selectedgroup").remove();
        }


        $(svg).mousemove(function (e) {
            if (movingPoint === null) return;
            let x = (e.pageX * scale) - downX;
            let y = (e.pageY * scale) - downY;
            movingPoint.x = x;
            movingPoint.y = y;
            movingCircle.setAttributeNS(null, 'cx', x);
            movingCircle.setAttributeNS(null, 'cy', y);

            if ('circle' in movingPoint) {
                for (let extra of movingPoint.circle) {
                    let [circle, point2] = extra;
                    let x = point2.x + (movingPoint.x - point2.x) / 2;
                    let y = point2.y + (movingPoint.y - point2.y) / 2;
                    circle.setAttributeNS(null, 'cx', x);
                    circle.setAttributeNS(null, 'cy', y);
                }
            }

        }).mouseup(function (e) {
            if (movingPoint) {
                let upX = (e.pageX * scale) - movingPoint.x;
                let upY = (e.pageY * scale) - movingPoint.y;
                if (upX === downX && upY === downY) {
                    deletePoint(movingPoint);
                    deleteCircle(movingCircle);
                }
                movingPoint = null;
                movingCircle = null;
            } else {
                if (self.polygonCallback) {
                    self.polygonCallback({
                        svgId: svgId,
                        polyId: polyId,
                        polyName: polyName,
                        points: [...polygon.points].map(p => p.x + "," + p.y).join(" ")
                    });
                }
            }
        });

        createCircles();

    };

    setAllowEditing(allow) {
        this.allowEditing = allow;
    };
}

export default DrawPolygons;
