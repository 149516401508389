import { getFavorites } from "./favorite-houses";

// Smooth scroll to anchor links
$('a:not(.no-smooth-scroll)').click(function(e) {
    scrollToAnchor(this.href, e);
});

$(window).ready(function() {
    setTimeout(function() {
    scrollToAnchor(window.location.href, null, 80);
    }, 100)
});

function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if (!$el || $el.length === 0) {
        console.error('No element found', '#' + id);
        return;
    }

    $('html, body').animate({
        scrollTop: $el.offset().top - 130 - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
    }
}

// Make logo smaller on scroll
var $h1 = $('header h1');
$(window).scroll(function() {
    if ($(this).scrollTop() > 10) {
        $h1.addClass('small');
    } else {
        $h1.removeClass('small');
    }
});


var favoritesCount = getFavorites().length;
if (favoritesCount > 0) {
    var $favoritesLink = $('.favorites-link');
    $favoritesLink.addClass('has-favorites');
    $favoritesLink.find('text').text('' + favoritesCount);

}

const setupBodyViewHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setupBodyViewHeight();
window.addEventListener('resize', setupBodyViewHeight);
