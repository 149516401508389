import {intersectionObserve} from "./_intersection-observer";

var $stickyHeader = $('.eat-banner .sticky-info');
var $banner = $('.eat-banner .banner-info');
if ($banner.length > 0) {
    intersectionObserve($banner[0], 0, function(element, intersectionRatio) {
        if (intersectionRatio > 0) {
            $stickyHeader.removeClass('show');
        } else {
            $stickyHeader.addClass('show');
        }
    });

    /*
    $(window).scroll(function() {
        var scrollTop = $(this).scrollTop();

        if (scrollTop > 10) {
            $stickyHeader.addClass('show');
        } else {
            $stickyHeader.removeClass('show');
        }
    });
     */
}
