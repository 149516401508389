import { intersectionObserve } from "./_intersection-observer";

$('#archive-fields .options-list .options a').click(function(e) {
    e.preventDefault();
    var $this = $(this);

    var href = e.target.href;
    var $subOption = $this.parents('.sub-option');
    var key = $subOption.data('option');

    updateOptionList(e.target, href, key, $this.data('slug'));
});

function updateOptionList(target, href, key, value, isSingle) {
    const $this = $(target);

    // Update history state
    if (window.history.pushState) {
        window.history.pushState({}, '', href);
    }

    // Toggle active class for the option
    $this.parent().toggleClass('active');

    // Update the options list
    var $optionList = $('.options-list');
    var newOptions = toggleFieldOption(key, value, isSingle);
    $optionList.data('options', serializeOptions(newOptions));

    // Update all option hrefs to match the new selection
    $optionList.find('.sub-option').each(function(i, option) {
        var $option = $(option);
        var key = $option.data('option');
        var options = $option.data('options');
        
        $option.find('a').each(function(j, a) {
            var $a = $(a);
            var slug = $a.data('slug');

            a.href = '?' + serializeOptions(toggleFieldOption(key, slug, isSingle));
        });

        if (options) {
            // Show selected options on mobile
            var currentOptions = newOptions[key];
            var names = [];
            for (var i = 0; i < currentOptions.length; i++) {
                var slug = currentOptions[i];
                var keys = Object.keys(options);
                for (var j = 0; j < keys.length; j++) {
                    var option = options[keys[j]];
                    if (option['slug'] === slug) {
                        names.push(option['name']);
                    }
                }
            }
            $option.find('.selected').text(names.join(', '));
        }
    });

    var $roomsOption = $optionList.find('.sub-option.rooms');
    if (newOptions.type.indexOf('leiligheter') !== -1) {
        $roomsOption.removeClass('hide');
    } else {
        $roomsOption.addClass('hide');
    }

    // Show loading indicator
    $('.loading-spinner').show();
    //$('.fields').hide();

    // Get the new field listing page, update the .fields div with the new html
    $.get(href, function(data) {
        var $html = $(data);
        var $newData = $('#archive-fields .fields-wrapper .fields', $html);
        $('#archive-fields .fields').remove();
        $('#archive-fields .fields-wrapper').prepend($newData);

        $('.loading-spinner').hide();
    });
}

$('#archive-fields .options-list .label a').click(function(e) {
    e.preventDefault();

    var $parentOption = $(this).parents('.sub-option').find('.options');
    var parentIsOpen = $parentOption.hasClass('open');

    $('.sub-option .options').removeClass('open');

    if (!parentIsOpen) {
        $parentOption.addClass('open');
    }

});

const timeoutMs = 500;
let priceMinTimeout = null;
const $priceMinInput = $('#archive-fields .options-list .options.price input#prismin');
let priceMinOldValue = $priceMinInput.val();

// Keep old value on keydown to compare against new value to see if it has changed
$priceMinInput.keydown(function(e) {
    priceMinOldValue = e.target.value.replace(/\s+/, '');
});
$priceMinInput.keyup(function(e) {
    const target = e.target;

    // Ignore if value is the same
    if (target.value === priceMinOldValue) {
        return;
    }

    formatNumberForInput(target);

    // Trampoline the keyup for x ms (wait for the user to stop typing)
    if (priceMinTimeout) {
        clearTimeout(priceMinTimeout);
    }

    priceMinTimeout = setTimeout(function () {
        changePrice(target);
        priceMinTimeout = null;
    }, timeoutMs);
});

let priceMaxTimeout = null;
const $priceMaxInput = $('#archive-fields .options-list .options.price input#prismaks');
let priceMaxOldValue = $priceMaxInput.val();

// Keep old value on keydown to compare against new value to see if it has changed
$priceMaxInput.keydown(function(e) {
    priceMaxOldValue = e.target.value;
});
$priceMaxInput.keyup(function(e) {
    const target = e.target;

    // Ignore if value is the same
    if (target.value === priceMaxOldValue) {
        return;
    }

    formatNumberForInput(target);

    // Trampoline the keyup for x ms (wait for the user to stop typing)
    if (priceMaxTimeout) {
        clearTimeout(priceMaxTimeout);
    }

    priceMaxTimeout = setTimeout(function () {
        changePrice(target);
        priceMaxTimeout = null;
    }, timeoutMs);
});

function changePrice(target) {
    const key = target.id;
    const number = target.value ? parseInt(target.value.replace(/\s+/g, '')) : 0;
    const href = window.location.pathname + '?' + serializeOptions(toggleFieldOption(key, number, true))

    updateOptionList(target, href, key, number,true);
}

function formatNumberForInput(target) {
    // Format number
    const number = target.value ? parseInt(target.value.replace(/\s+/g, '')) : 0;
    const originalValue = target.value;
    const originalLength = originalValue.length;
    let caretPosition = target.selectionStart;
    target.value = number === 0 ? '' : number.toLocaleString('nb-NO');
    const updatedLength = target.value.length;
    caretPosition = updatedLength - originalLength + caretPosition;
    target.setSelectionRange(caretPosition, caretPosition);
}

$('.change-options').click(function(e) {
    e.preventDefault()

    $('html, body').animate({
        scrollTop: 0
    }, 300);
});

function parseFieldOptions(str) {
    var components = str.split('&');

    var areaComponents = components[0].split('=');
    var areas = areaComponents[1].length > 0 ? areaComponents[1].split(',') : [];

    var housingTypeComponents = components[1].split('=');
    var housingTypes = housingTypeComponents[1].length > 0 ? housingTypeComponents[1].split(',') : [];

    var roomComponents = components[2].split('=');
    var rooms = roomComponents[1].length > 0 ? roomComponents[1].split(',') : [];

    var priceMinComponents = components[3].split('=');
    var priceMin = priceMinComponents[1].length > 0 ? priceMinComponents[1].split(',') : [];

    var priceMaxComponents = components[4].split('=');
    var priceMax = priceMaxComponents[1].length > 0 ? priceMaxComponents[1].split(',') : [];
    
    return {
        omrade: areas,
        type: housingTypes,
        rom: rooms,
        prismin: priceMin,
        prismaks: priceMax
    }
}

function toggleFieldOption(type, option, isSingle) {
    let options = parseFieldOptions($('.options-list').data('options'));

    let list = options[type];

    if (isSingle) {
        list = [option];
    } else {
        const index = list ? list.indexOf('' + option) : -1;
        if (index !== -1) {
            list.splice(index, 1);
        } else {
            list.push(option);
        }
    }

    options[type] = list;
    return options;
}

function serializeOptions(options) {
    var keys = Object.keys(options);
    var components = [];
    for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        components.push(key + '=' + options[key].join(','));
    }

    return components.join('&');
}

var $stickyHeader = $('#archive-fields .sticky-header');
var $fieldOptions = $('#archive-fields .field-options');
if ($fieldOptions.length > 0) {
    intersectionObserve($fieldOptions[0], 0, function(element, intersectionRatio) {
        if (intersectionRatio > 0) {
            $stickyHeader.removeClass('show');
        } else {
            $stickyHeader.addClass('show');
        }
    });

    /*
    $(window).scroll(function() {
        var scrollTop = $(this).scrollTop();

        if (scrollTop > 0) {
            $stickyHeader.addClass('show');
        } else {
            $stickyHeader.removeClass('show');
        }
    });
    */
}