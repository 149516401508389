
$('.homepage-box .expand a').click(function(e) {
    e.preventDefault();

    $(this).parents('.homepage-box').toggleClass('open');
    $(this).parents('.homepage-box').toggleClass('mobile-open');
});

$('.mobile-close a').click(function(e) {
    e.preventDefault();

    $(this).parents('.homepage-box').toggleClass('open');
    $(this).parents('.homepage-box').toggleClass('mobile-open');
});

var $homepageBoxes = $('.homepage-box');
var index = -1;
if ($homepageBoxes.length === 1) {
    index = 0;
} else if ($homepageBoxes.length > 1) {
    index = Math.floor(Math.random() * $homepageBoxes.length);
}

if (index >= 0) {
    $($homepageBoxes[index]).removeClass('hidden');
}

var hideAfter = parseInt($('.homepage-box').data('hideAfter')) || 0;
if (hideAfter) {
    setTimeout(function() {
        $homepageBoxes.removeClass('open');
        $homepageBoxes.removeClass('mobile-open');
    }, hideAfter * 1000)
}
