var $peopleBlocks = $('.block-people');
if ($peopleBlocks.length > 0) {
    $peopleBlocks.each(function(index, el) {
        (function($el) {
            $(window).scroll(function() {
                var $selector = $el.find('.back-to-links-selector');
                var elementTop = $el.offset().top;
                var elementHeight = $el.height();
                var scrollTop = $(this).scrollTop();

                if (scrollTop > elementTop + 10 && scrollTop < elementTop + elementHeight) {
                    $selector.addClass('show');
                    setTimeout(function() {
                        $selector.addClass('fade');
                    }, 10);
                } else {
                    $selector.removeClass('fade');
                    setTimeout(function() {
                        $selector.removeClass('show');
                    });
                }
            });
        })($(el));
    });
}
