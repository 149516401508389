$('header .menu-button').click(function(e) {
    e.preventDefault();

    $('header nav').addClass('show');
    setTimeout(function() {
        $('nav').addClass('animate-show');
        $('header nav.main .close-menu a').focus();
    }, 0);
});

$('.close-menu a').click(function(e) {
    e.preventDefault();
    closeMenu();
});

$('header nav').click(function(e) {
    if ($(e.target).parents('nav').length) {
        return;
    }

    closeMenu();
});

function closeMenu() {
    $('header nav').removeClass('animate-show');
    setTimeout(function() {
        $('header nav').removeClass('show');
    }, 200);
}