var $stickyPageTitle = $('.sticky-page-title');
if ($stickyPageTitle.length > 0) {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 10) {
            $stickyPageTitle.addClass('small');
        } else {
            $stickyPageTitle.removeClass('small');
        }
    });

    $('header h1 img').addClass('fixed');
}