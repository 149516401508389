$contactInterestForm = $('.contact-interest-form');
$contactInterestForm.submit(function(e) {
    e.preventDefault();

    submitInterest(e.target);
});

$contactInterestForm.find('select.contact-interest-crm').change(function(e) {
    const crm_type = $(e.target).find('option:selected').data('crmType')
    $contactInterestForm.find('#crm_type').val(crm_type)
});

$('.subfield-intro .contact form').submit(function(e) {
    e.preventDefault();

    submitInterest(e.target);
})

function submitInterest(target) {
    let $form = $(target);
    let formData = $form.serializeArray();
    let $response = $form.find('.response');
    let $button = $form.find('button');

    let data = new FormData();
    data.append('action', 'submit_interest');

    for (let i = 0; i < formData.length; i++) {
        let name = formData[i]['name'];
        let value = formData[i]['value'];
        data.append(name, value);
    }

    $button.blur();
    $button.addClass('loading');
    $button.prop('disabled', true);
    $response.hide();

    if ($form.hasClass('hide-button-content-on-load')) {
        $button.find('.content').hide();
    }

    $.ajax({
        url: osterhus_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function(data) {
            $response.addClass('error');

        },
        complete: function(data) {
            try {
                let json = JSON.parse(data.responseText);
                $response.html(json.message);

                if (json.status === 'failed') {
                    $response.addClass('error');
                } else {
                    $form[0].reset();
                    $response.addClass('success');
                }
            }
            catch (error) {
                $response.addClass('error');
                $response.html('En ukjent feil oppsto: ' + error);
            }

            $response.show();
            $button.find('.content').show();
            $button.removeClass('loading');
            $button.prop('disabled', false);
        }
    });
}
