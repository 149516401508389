import {addToFavorites, getFavorites} from "./favorite-houses";

function renderFavoriteUnits() {
    if (!$('body').hasClass('page-template-page-favorites')) {
        return;
    }

    var favorites = getFavorites();

    var $favoritesLink = $('.favorites-link');

    if (favorites.length === 0) {
        $('.no-favorites').show();
        $('#content-section').css({display: 'flex', 'flex-direction': 'column'});
        $('.compare-favorites').hide();
        $favoritesLink.removeClass('has-favorites');
        $favoritesLink.find('text').text('');
        return;
    }

    $('.no-favorites').hide();
    $('.compare-favorites').show();
    $favoritesLink.find('text').text('' + favorites.length);

    var $unitSelect = $('select.unit-select');
    $unitSelect.change(function(e) {
        var $select1 = $($unitSelect[0]);
        var $select2 = $($unitSelect[1]);
        var $select3 = $($unitSelect[2]);
        var slug1 = $select1.val();
        var slug2 = $select2.val();
        var slug3 = $select3.val();
        var $option1 = $select1.find('option[value="' + slug1 + '"]');
        var $option2 = $select2.find('option[value="' + slug2 + '"]');
        var $option3 = $select3.find('option[value="' + slug3 + '"]');
        var pageId1 = $option1.data('pageId');
        var pageId2 = $option2.data('pageId');
        var pageId3 = $option3.data('pageId');

        loadData(pageId1, slug1, pageId2, slug2, pageId3, slug3, '.compare-favorites .favorites');
    });

    let data = new FormData();
    data.append('action', 'get_favorites');
    data.append('favorites', JSON.stringify(favorites));

    $.ajax({
        url: osterhus_scripts.ajax_url,
        type: 'POST',
        data : data,
        cache: false,
        processData: false,
        contentType: false,
    }).done(function(data) {
        var units = JSON.parse(data);

        $unitSelect.html('');
        $unitSelect.append('<option value="">Velg bolig</option>');
        for (var i = 0; i < units.length; i++) {
            var unit = units[i];
            $unitSelect.append('<option value="' + unit.unit_slug + '" data-page-id="' + unit.page_id + '">' + unit.page_title + ' – ' + unit.title + '</option>');
        }

        $('.unit-selection').css('display', 'flex');

        if (units.length > 0) {
            var unit1 = units[0];
            var $unitSelect1 = $($unitSelect[0]);
            $unitSelect1.val(unit1.unit_slug);

            var $unitSelect2 = $($unitSelect[1]);
            var $unitSelect3 = $($unitSelect[2]);

            if (units.length > 1) {
                var unit2 = units[1];
                $unitSelect2.val(unit2.unit_slug);

                if (units.length > 2) {
                    var unit3 = units[2];
                    $unitSelect3.val(unit3.unit_slug);
                    loadData(unit1.page_id, unit1.unit_slug, unit2.page_id, unit2.unit_slug, unit3.page_id, unit3.unit_slug, '.compare-favorites .favorites');
                } else {
                    loadData(unit1.page_id, unit1.unit_slug, unit2.page_id, unit2.unit_slug, null, null, '.compare-favorites .favorites');
                }
            } else {
                loadData(unit1.page_id, unit1.unit_slug, null, null, '.compare-favorites .favorites');

                $('.unit-selection').css('display', 'none');
            }
        }
    });
}

function loadData(pageId1, unitSlug1, pageId2, unitSlug2, pageId3, unitSlug3, targetDiv) {
    let data = new FormData();
    data.append('action', 'get_favorites_unit_data');
    data.append('page_id_1', pageId1);
    data.append('unit_slug_1', unitSlug1);
    data.append('page_id_2', pageId2);
    data.append('unit_slug_2', unitSlug2);
    data.append('page_id_3', pageId3);
    data.append('unit_slug_3', unitSlug3);

    $(targetDiv).hide();
    $('.loading').show();

    $.ajax({
        url: osterhus_scripts.ajax_url,
        type: 'POST',
        data : data,
        cache: false,
        processData: false,
        contentType: false,
    }).done(function(data) {
        $('.loading').hide();
        $(targetDiv).html(data);
        $(targetDiv).show();

        // Find tallest h2 and use this as the min-height
        var $h2 = $('.favorite-house h2');
        var maxHeight = 0;
        for (var i = 0; i < $h2.length; i++) {
            var $h = $($h2[i]);
            maxHeight = Math.max(maxHeight, $h.height());
        }
        $h2.css('min-height', maxHeight);

        // Find tallest "key info" area and use this as the min-height
        var $keyInfo = $('.key-info');
        var maxHeight = 0;
        for (var i = 0; i < $keyInfo.length; i++) {
            var $info = $($keyInfo[i]);
            maxHeight = Math.max(maxHeight, $info.height());
        }
        $keyInfo.css('min-height', 'calc(' + maxHeight + 'px + 2.5em)');

        $(targetDiv).find('.remove-favorite').click(function(e) {
            e.preventDefault();

            var $this = $(this);
            var pageId = $this.data('pageId');
            var slug = $this.data('unitId');

            addToFavorites(pageId, slug);

            $(targetDiv).fadeOut();
            renderFavoriteUnits();
        })
    });
}

renderFavoriteUnits();