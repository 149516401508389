import {intersectionObserve} from "./_intersection-observer";

if ($('.sticky-downloads').length > 0) {

    $(window).scroll(function(e) {
        var scrollTop = $(e.target).scrollTop();
        var headerHeight = 130 + ($('#wpadminbar').outerHeight() || 0) + 20; // 20px from the bottom of the header

        if (window.innerWidth >= 1000) {
            var $download = $('.subfield-intro .downloads');

            if ($download && $download.length > 0) {
                var downloadTop = $download.offset().top;
                var downloadHeight = $download.height();

                if (scrollTop > downloadTop + downloadHeight - headerHeight) {
                    $('.sticky-downloads').addClass('show');
                } else {
                    $('.sticky-downloads').removeClass('show');
                }
            }
        }

        var $unitList = $('.unit-list');
        var unitListTop = $unitList.offset().top;
        var unitListHeight = $unitList.height();
        var $backToUnitSelector = $('.back-to-unit-selector');

        if (scrollTop > unitListTop + unitListHeight - headerHeight) {
            $backToUnitSelector.addClass('show');
            setTimeout(function() {
                $backToUnitSelector.addClass('fade');
            }, 10);
        } else {
            $backToUnitSelector.removeClass('fade');
            setTimeout(function() {
                $backToUnitSelector.removeClass('show');
            });
        }

    });

}

var $stickyHeader = $('.subfield-banner .sticky-info');
var $banner = $('.subfield-banner .banner .info');
if ($banner.length > 0) {
    intersectionObserve($banner[0], 0, function(element, intersectionRatio) {
        if (intersectionRatio > 0) {
            $stickyHeader.removeClass('show');
        } else {
            $stickyHeader.addClass('show');
        }
    });

    /*
    $(window).scroll(function () {
        var scrollTop = $(this).scrollTop();

        if (scrollTop > 0) {
            $stickyHeader.addClass('show');
        } else {
            $stickyHeader.removeClass('show');
        }
    });
     */
}