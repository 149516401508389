import noUiSlider from 'nouislider'

$('#archive-eat .options-list .options a').click(function(e) {
    e.preventDefault();
    var $this = $(this);

    var href = e.target.href;
    var key = $this.parents('.sub-option').data('option');

    // Update history state
    if (window.history.pushState) {
        window.history.pushState({}, '', href);
    }

    // Toggle active class for the option
    $this.parent().toggleClass('active');

    // Update the options list
    var $optionList = $('.options-list');
    var newOptions = toggleFieldOption(key, $this.data('slug'));
    $optionList.data('options', serializeOptions(newOptions));

    // Update all option hrefs to match the new selection
    $optionList.find('.sub-option').each(function(i, option) {
        var $option = $(option);
        var key = $option.data('option');

        $option.find('a').each(function(j, a) {
            var $a = $(a);
            var slug = $a.data('slug');

            a.href = '?' + serializeOptions(toggleFieldOption(key, slug));
        });
    });

    // Get the new field listing page, update the .fields div with the new html
    $.get(href, function(data) {
        var $html = $(data);
        var $newData = $('#archive-eat .houses-wrapper .houses', $html);
        $('#archive-eat .houses').remove();
        $('#archive-eat .houses-wrapper').prepend($newData);
    });
});

$('#archive-eat .options-list .label a').click(function(e) {
    e.preventDefault();

    $(this).parents('.sub-option').find('.options').toggleClass('open');

});

function parseFieldOptions(str) {
    var components = str.split('&');

    var housingTypeComponents = components[0].split('=');
    var housingTypes = housingTypeComponents[1].length > 0 ? housingTypeComponents[1].split(',') : [];

    return {
        type: housingTypes
    }
}

function toggleFieldOption(type, option) {
    var options = parseFieldOptions($('.options-list').data('options'));

    var list = options[type];

    if (list) {
        var index = list.indexOf('' + option);
        if (index !== -1) {
            list.splice(index, 1);
        } else {
            list.push(option);
        }

        options[type] = list;
    }

    return options;
}

function serializeOptions(options) {
    var keys = Object.keys(options);
    var components = [];
    for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        components.push(key + '=' + options[key].join(','));
    }

    return components.join('&');
}

var $braSlider = $('.bra-range-slider .range-slider');
var $braSliderLabel = $('.bra-range-slider .range-slider-label');
if ($braSlider.length > 0) {
    var min = parseInt($braSlider.data('min')) || 0;
    var max = parseInt($braSlider.data('max')) || 0;

    var braSlider = noUiSlider.create($braSlider[0], {
        start: [min, max],
        step: 10,
        connect: true,
        range: {
            'min': 0,
            'max': 550
        }
    });

    braSlider.on('update', function() {
        var range = getRange(braSlider.get());
        var min = range[0];
        var max = range[1];

        $braSliderLabel.html(min + ' m² – ' + max + ' m²');
    });

    braSlider.on('change', function() {
        var range = getRange(braSlider.get());
        var min = range[0];
        var max = range[1];

        var options = parseFieldOptions($('.options-list').data('options'));
        options['bra'] = range;
        var href = '?' + serializeOptions(options);

        // Update history state
        if (window.history.pushState) {
            window.history.pushState({}, '', href);
        }

        // Get the new field listing page, update the .fields div with the new html
        $.get(href, function(data) {
            var $html = $(data);
            var $newData = $('#archive-eat .houses-wrapper .houses', $html);
            $('#archive-eat .houses').remove();
            $('#archive-eat .houses-wrapper').prepend($newData);
        });
    });
}

function getRange(values) {
    return [parseInt(values[0]) || 0, parseInt(values[1]) || 0]
}