
$('.single-text-block .expand').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var $expandableContent = $this.siblings('.expandable-content');
    var isOpen = $expandableContent.hasClass('open');

    $this.find('.title').html(isOpen ? 'Les mer' : 'Vis mindre');
    $expandableContent.slideToggle();
    $expandableContent.toggleClass('open');
    $this.toggleClass('open');
})