/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

import mapStyles from "./map-styles";

function new_map($el) {

    // var
    var $markers = $el.find('.marker');

    var zoom = $el.data('zoomLevel') || 16;
    var mapType = $el.data('mapType');

    // vars
    var args = {
        zoom: zoom,
        center: new google.maps.LatLng(0, 0),
        mapTypeId: mapType,
        styles: mapStyles
    };


    // create map
    var map = new google.maps.Map($el[0], args);


    // add a markers reference
    map.markers = [];


    // add markers
    $markers.each(function () {

        add_marker($(this), map);

    });


    // center map
    center_map(map);


    // return
    return map;

}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

function add_marker($marker, map) {

    // var
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    var url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70.6 91.3" style="enable-background:new 0 0 70.6 91.3;" xml:space="preserve"><path fill="#DE0B1C" d="M35.3,0C15.8,0,0,15.8,0,35.3c0,28.5,35.3,56,35.3,56s35.3-26.5,35.3-56C70.6,15.8,54.8,0,35.3,0z M35.3,46.1c-7,0-12.8-5.7-12.8-12.8s5.8-12.7,12.8-12.7s12.8,5.7,12.8,12.8S42.3,46.1,35.3,46.1L35.3,46.1z"/></svg>');
    var icon = {
        url: url,
        scaledSize: new google.maps.Size(35, 35)
    };

    // create marker
    var marker = new google.maps.Marker({
        position: latlng,
        map: map,
        icon: icon
    });

    // add to array
    map.markers.push(marker);

    marker.addListener('click', function(m) {
        var url = 'https://www.google.com/maps/dir/?api=1&destination=' + m.latLng.lat() + ',' + m.latLng.lng();
        window.open(url, '_blank');
    });

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map(map) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {

        var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

        bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
        // set center of map
        map.setCenter(bounds.getCenter());
    }
    else {
        // fit to bounds
        map.fitBounds(bounds);
    }

}

/*
*  document ready
*
*  This function will render each map when the document is ready (page has loaded)
*
*  @type	function
*  @date	8/11/2013
*  @since	5.0.0
*
*  @param	n/a
*  @return	n/a
*/
// global var
var map = null;

var initMap = function() {

    $('.acf-map:not(.initialized)').each(function () {
        var $this = $(this);

        if (window.google === undefined || window.google.maps === undefined) {
            return;
        }

        // create map
        map = new_map($this);

        $this.addClass('initialized');

    });

    if (window.initSubfieldMap) {
        window.initSubfieldMap();
    }

};

window.initMap = initMap;
