import Swipe from 'swipejs'

function initSliders() {
    var sliders = document.querySelectorAll(".media-wrap.has-slides:not(.initialized)");
    Array.prototype.forEach.call(sliders, function (el, i) {
        var animate = el.getAttribute('data-animate');
        var autoSpeed = el.getAttribute('data-auto-speed');
        var navigation = el.querySelector('.slider-navigation');
        var navChildren = navigation ? navigation.querySelectorAll('a') : [];
        var speed = 500;

        Window["slider-" + i] = new Swipe(el, {
            startSlide: 0,
            draggable: true,
            continuous: true,
            disableScroll: true,
            auto: animate ? (parseInt(autoSpeed) || 4000) : 0,
            speed: speed,
            callback: function (index) {
                if (navigation == null) {
                    return;
                }

                Array.prototype.forEach.call(navChildren, function (child) {
                    child.classList.remove('active');
                });

                navChildren[index].classList.add('active');
            }
        });

        Array.prototype.forEach.call(navChildren, function (child, index) {
            child.addEventListener('click', function (e) {
                e.preventDefault();
                Window["slider-" + i].slide(index, speed);
            });
        });

        el.classList.add('initialized');
    });

    var slider_prev = document.querySelectorAll(".nav.nav-left");
    Array.prototype.forEach.call(slider_prev, function (el, i) {
        el.addEventListener("click", function (e) {
            e.preventDefault();
            Window["slider-" + i].prev();
        });
    });

    var slider_next = document.querySelectorAll(".nav.nav-right");
    Array.prototype.forEach.call(slider_next, function (el, i) {
        el.addEventListener("click", function (e) {
            e.preventDefault();
            Window["slider-" + i].next();
        });
    });
}

window.initSliders = initSliders;
initSliders();